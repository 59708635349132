@import "../../../assets/commonStyle/colors";
@import "../../../assets/commonStyle/mixins";

.questionaries_outer {
    .q_n_a_outer {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
        .qstn {
            @include fontStyle(#000, 1rem, 500);
        }
        .ans {
            p {
                @include fontStyle(#706f6d, 1rem, 400);
                word-wrap: break-word;
            }
            img {
                width: 15rem;
            }
        }
    }
}
