@import "../../../assets/commonStyle/colors";
@import "../../../assets/commonStyle/mixins";

.image_details_container {
    .header_part {
        p {
            @include fontStyle(#00000099, 1.4rem, 600);
        }
    }
    .minor_damage_switch_part {
        margin: 1rem 0 2rem 0;
    }
    .image_details_container_inner {
        @include displayFlex(space-between, stretch);
        flex-wrap: wrap;
        @media (max-width: 991px) {
            justify-content: center;
        }
        .image_details_card_inner {
            width: 30rem;
            margin: 0 1rem 2rem 1rem;
            @media (max-width: 575px) {
                width: 100%;
            }
            .card_title {
                @include fontStyle(#000000, 1.2rem, 500);
                padding: 0 0 0.25rem 1rem;
            }
            .image_details_card_body_inner {
                padding: 1rem;
                background-color: #f5f5f5;
                border-radius: 1rem;
                height: calc(100% - 2.25rem);
                width: 100%;
                @include displayFlex(space-between, stretch);
                .left_part {
                    width: 15rem;
                    .image_container {
                        width: 100%;
                        position: relative;
                        border-radius: 1rem;
                        .car_img {
                            width: 100%;
                            display: block;
                            margin: 0 auto;
                            border-radius: 1rem;
                            border: 0.01rem solid #000000;
                        }
                        .enlarge_icon {
                            position: absolute;
                            top: 0.25rem;
                            right: 0.5rem;
                            width: 2rem;
                            cursor: pointer;
                        }
                        .icons_container {
                            position: absolute;
                            bottom: 0.25rem;
                            left: 0.5rem;
                            img {
                                cursor: pointer;
                                width: 1.25rem;
                                margin-bottom: 0.5rem;
                            }
                        }
                    }
                    .enlarge_button {
                        margin-top: 0.7rem;
                        width: 100%;
                        display: none;
                        @media (max-width: 767px) {
                            display: block;
                        }
                        button {
                            width: 100%;
                            background-color: #005a88;
                            padding: 0.25rem;
                            border-radius: 0.5rem;
                            @include fontStyle(#fff, 0.9rem, 500);
                        }
                    }
                }
                .right_part {
                    width: calc(100% - 15rem);
                    padding-left: 1rem;
                    .data_details {
                        &:not(:last-child) {
                            margin-bottom: 0.75rem;
                        }
                        .head_part {
                            p {
                                @include fontStyle(#706f6d, 1rem, 600);
                            }
                        }
                        .sub_part {
                            p {
                                @include fontStyle(#000, 1.2rem, 600);
                            }
                        }
                    }
                }
            }
        }
    }
}

// DIALOG - IMAGE
.image_details_dialog_outer {
    .image_details_dialog_header {
        border-bottom: solid #00000033 0.05rem;
        padding: 0.5rem 1rem;
        @include displayFlex(space-between, center);
        p {
            @include fontStyle(#000, 1.2rem, 500);
        }
        .close_icon {
            font-size: 1.5rem;
        }
    }
    .dialog_content_part {
        .toggle_part {
            &:first-child {
                margin-top: 0.5rem;
            }
        }
        .image_and_icons {
            padding-top: 1rem;
            position: relative;
            width: max-content;
            margin: 0 auto;
            .selected_img {
                max-width: 24rem;
                max-height: 60vh;
                margin: 0 auto;
                object-fit: contain;
                @media (max-width:400px) {
                    max-width: 20rem;
                }
            }
            .icons_container {
                position: absolute;
                bottom: 0.5rem;
                left: 0.5rem;
                img {
                    cursor: pointer;
                    width: 1.5rem;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}

.loading_skeleton {
    background-color: #f0f0f0; /* Initial background color */
    width: 100%;
    height: 17rem;
    border-radius: 0.5rem;
    animation: loadingGradient 2s ease-in-out infinite;
}

@keyframes loadingGradient {
    0% {
        background-position: -150% 0;
    }
    50% {
        background-position: 150% 0;
    }
    100% {
        background-position: -150% 0;
    }
}

/* Adding gradient to enhance the transition effect */
.loading_skeleton:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, #f0f0f0 25%, #dedcdcf5 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: skeletonAnimation 2s infinite ease-in-out;
    border-radius: 0.5rem;
}

@keyframes skeletonAnimation {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
