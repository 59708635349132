@import "../../../assets/commonStyle/colors";
@import "../../../assets/commonStyle/mixins";

.report_header1 {
    header {
        padding: 0.8rem;
        @include displayFlex(space-between, center);
        box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
        .left_part {
            .logo_img {
                width: 7rem;
                display: block;
                @media (max-width: 767px) {
                    display: none;
                }
            }
            .qr_part {
                display: none;
                @media (max-width: 767px) {
                    @include displayFlex(flex-start, center);
                }
                img {
                    width: 2.5rem;
                }
                p {
                    padding-left: 0.75rem;
                    @include fontStyle(#005a88, 1rem, 400);
                    cursor: pointer;
                }
            }
        }
        .right_part {
            button {
                padding: 0 1rem;
                border: #b2b2b2 solid 1px;
                border-radius: 0.35rem;
                @include fontStyle(#706f6d, 1rem, 400);
                text-transform: none;
                img {
                    height: 2.25rem;
                }
            }
        }
    }
}

.report_header2 {
    padding: 2rem 0.8rem 1rem 0.8rem;
    header {
        @include displayFlex(space-between, stretch);
        @media (max-width: 767px) {
            flex-direction: row-reverse;
        }
        .left_part {
            @include displayFlex(space-between, stretch);
            width: calc(100% - 15rem);
            padding-right: 1rem;
            @media (max-width: 767px) {
                max-width: calc(100% - 10rem);
                width: max-content;
            }
            .scanner_part {
                width: 8rem;
                @media (max-width: 767px) {
                    display: none;
                }
                img {
                    width: 100%;
                }
            }
            .date_time_part {
                padding-left: 1rem;
                width: calc(100% - 8rem);
                @media (max-width: 767px) {
                    width: 100%;
                }
                .header_text {
                    @include fontStyle(#00000099, 1.5rem, 600);
                    @media (max-width: 767px) {
                        font-size: 1.2rem;
                    }
                }
                .date_or_time_container {
                    margin-top: 0.5rem;
                    @include displayFlex(flex-start, center);
                    img {
                        width: 1.2rem;
                    }
                    p {
                        padding-left: 0.5rem;
                        @include fontStyle(#706f6d, 1rem, 400);
                    }
                }
            }
        }
        .right_part {
            width: 15rem;
            @media (max-width: 767px) {
                width: 10rem;
            }
            .client_logo {
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
    }
}

.qr_details_dialog_outer {
    .qr_details_dialog_header {
        border-bottom: solid #00000033 0.05rem;
        padding: 0.5rem 1rem;
        @include displayFlex(space-between, center);
        p {
            @include fontStyle(#000, 1.2rem, 500);
        }
        .close_icon {
            font-size: 1.5rem;
        }
    }
    .dialog_content_part {
        img {
            height: 40vh;
            margin: 1rem auto;
            display: block;
        }
        .copy_part {
            @include displayFlex(space-between, center);
            background-color: #d9d9d9;
            border-radius: 0.5rem;
            padding: 0.5rem 0.75rem;
            .left_part {
                width: calc(100% - 2.5rem);
                p {
                    padding-right: 0.5rem;
                    @include fontStyle(#000, 1.3rem, 500);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .right_part {
                width: 2.5rem;
                @include displayFlex(flex-end, center);
                border-left: solid 0.05rem #5f6368;
                .copy_icon_outer {
                    padding: 0;
                    .copy_icon {
                        @include fontStyle(#5f6368, 2rem, 500);
                    }
                }
            }
        }
    }
}
