@mixin displayFlex($justifyContent, $alignItems) {
    display: flex;
    justify-content: $justifyContent;
    align-items: $alignItems;
}

@mixin fontStyle($color, $fontSize, $fontWeight) {
    color: $color;
    font-size: $fontSize;
    font-weight: $fontWeight;
}

@mixin color_bgcolor($color, $bgcolor) {
    color: $color;
    background-color: $bgcolor;
}

