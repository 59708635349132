@import "../../../assets/commonStyle/colors";
@import "../../../assets/commonStyle/mixins";

.report_side_wise_view {
    background-color: #f5f5f5;
    border-radius: 0.5rem;
    .accordian_body_outer {
        border: none;
        background-color: #f5f5f5;
    }
    .accordian_details_outer {
        background-color: transparent;
        .MuiOutlinedInput-root {
            background-color: #d9d9d980;
            &.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: #b2b2b2;
            }
            .MuiOutlinedInput-notchedOutline {
                border-color: #b2b2b2;
            }
        }
        .side_selection_dropdown {
            width: 17rem;
            @media (max-width: 575px) {
                display: none;
            }
        }
        .side_selection_button_toggle {
            max-width: 100%;
            overflow-x: auto;
            border-radius: 0;
            button {
                white-space: nowrap;
                background-color: transparent;
                border: none;
                border-radius: 0;
                padding: 0.25rem 1rem;
            }
            .css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
                border-bottom: solid 0.1rem #444;
            }
            display: none;
            @media (max-width: 575px) {
                display: flex;
            }
        }
        .rounded_clickable_button_container {
            @include displayFlex(center, center);
            flex-wrap: wrap;
            button {
                max-width: 1rem;
                max-height: 1rem;
                min-width: 1rem;
                min-height: 1rem;
                border-radius: 100%;
                background-color: #d9d9d9;
                &:not(:last-child) {
                    margin-right: 0.5rem;
                }
                @media (max-width: 575px) {
                    padding: 0.25rem;
                }
            }
            .selectedSideRoundedElement {
                background-color: #000;
            }
        }
    }
}

.side_wise_data_view_outer {
    margin: 2rem 0 1rem 0;
}

.damage_highlighters_outer {
    margin: 2rem 0 0 0;
    @include displayFlex(center, center);
    flex-wrap: wrap;
    .highlighter_inner {
        @include displayFlex(center, center);
        &:not(:last-child) {
            margin-right: 2rem;
        }
        @media (max-width: 767px) {
            margin-bottom: 0.5rem;
        }
        .severity_ball {
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
        }
        .severity_name {
            p {
                padding-left: 1rem;
                @include fontStyle(#000, 0.9rem, 300);
            }
        }
    }
}

.damage_type_data_outer {
    border: 0.05rem solid rgba(39, 37, 34, 0.6);
    @include displayFlex(flex-start, stretch);
    border-radius: 0.5rem;
    opacity: 0;
    transition:
        opacity 0.5s ease-in-out,
        transform 0.5s ease-in-out;

    &.fade-in {
        opacity: 1;
        transform: translateX(0);
    }

    &.fade-out {
        opacity: 0;
    }
    img {
        pointer-events: none;
    }
    ::selection {
        pointer-events: none;
    }
    @media (max-width: 767px) {
        display: block;
    }
    .left_part {
        width: 17rem;
        border-radius: 0.35rem 0 0 0.35rem;
        background: rgba(77, 77, 77, 0.8);
        border-right: #000 solid 0.05rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        position: relative;
        z-index: 9;
        @media (max-width: 767px) {
            width: 100%;
            border-right: none;
            border-radius: 0.35rem 0.35rem 0 0;
            height: 30rem;
        }
        .claimed_text {
            position: absolute;
            left: -0.9rem;
            top: 1rem;
            background-color: #be2600;
            padding: 0.1rem 0.4rem 0.1rem 0.4rem;
            border-radius: 0 0.2rem 0.2rem 0.2rem;
            z-index: 9;
            &::before {
                content: "";
                top: -0.8rem;
                left: -0rem;
                width: 0;
                height: 0;
                border-top: 0.8rem solid transparent;
                border-right: 0.95rem solid #cfcdc9;
                border-bottom: 0 solid transparent;
                position: absolute;
                z-index: -1;
            }
            p {
                padding: 0;
                @include fontStyle(#fff, 0.8rem !important, 400 !important);
            }
        }
        .left_part_inner {
            width: 100%;
            position: relative;
            .left_part_inner_wrapper {
                position: relative;
                .icon_outer {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #fff;
                    border-radius: 100%;
                    width: 2rem;
                    height: 2rem;
                    display: none;
                    @media (max-width: 767px) {
                        @include displayFlex(center, center);
                    }
                }
                .prev_icon_outer {
                    left: -0.5rem;
                }
                .next_icon_outer {
                    right: -0.5rem;
                }
                .prev_next_icon {
                    @include fontStyle(#000000, 2rem, 500);
                }
                img {
                    position: absolute;
                    width: 100%;
                    margin: 0 auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    object-fit: contain;
                    @media (max-width: 767px) {
                        width: auto;
                        height: 20rem;
                        max-height: 20rem;
                        padding: 0 2.25rem;
                    }
                }
                .main_part_image_with_zero_opacity {
                    position: static !important;
                    opacity: 0;
                    top: 0 !important;
                    transform: none !important;
                }
            }
        }
        p {
            text-align: center;
            padding-top: 1rem;
            @include fontStyle(#fff, 1rem, 400);
        }
    }

    .right_data_part {
        width: calc(100% - 17rem);
        @media (max-width: 767px) {
            width: 100%;
        }
        table {
            width: 100%;
            border-spacing: 0;
            border: none;

            thead {
                background: #f2f2f2;

                th {
                    @include fontStyle(#000, 1rem, 400);
                    vertical-align: middle;

                    &:last-child {
                        border-radius: 0 0.5rem 0 0;
                    }
                }
            }

            th,
            td {
                text-align: left;
                padding-top: 0.65rem;
                padding-bottom: 0.65rem;
            }

            th:nth-child(2),
            td:nth-child(2) {
                width: 21rem;
                padding-left: 3rem;
                padding-right: 1rem;
                @media (max-width: 991px) {
                    width: 17rem;
                }
                @media (max-width: 767px) {
                    width: 12rem;
                    padding-left: 1rem;
                    padding-right: 0;
                }
            }

            th:nth-child(3),
            td:nth-child(3) {
                width: 3rem;
                text-align: center;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            th:nth-child(4),
            td:nth-child(4) {
                padding-left: 8rem;
                padding-right: 1rem;
                @media (max-width: 767px) {
                    padding-left: 1rem;
                }
            }

            tbody {
                td {
                    position: relative;
                    border-top: 0.05rem solid rgba(178, 178, 178, 0.8);
                    @include fontStyle(#272522, 1rem, 300);
                    vertical-align: middle;

                    .severity_ball {
                        width: 1rem;
                        height: 1rem;
                        border-radius: 100%;
                        margin: 0 auto;
                    }
                }
            }

            .claim_icon_col {
                width: 2.25rem;
                padding-left: 0.5rem;
                img {
                    min-width: 1.75rem;
                    max-width: 1.75rem;
                }
            }

            .repairer_replace_col {
                width: 8rem;
                padding-right: 1rem;
                position: relative;
                img {
                    min-width: 5rem;
                    max-width: 5rem;
                    margin: 0 0 0 auto;
                    display: block;
                }
            }
        }
    }
}

.red_ball {
    background-color: #c90606;
}

.orange_ball {
    background-color: #fa7700;
}

.light_green_ball {
    background-color: #00de25;
}

.deep_green_ball {
    background-color: #036912;
}

.yellow_ball {
    background-color: #fec141;
}

.no_damage_ball {
    background-color: #bdbdbd;
}
