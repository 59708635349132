@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", sans-serif;
}

html {
  @media (max-width: 1199px) {
    font-size: 90%;
  }
  @media (max-width: 991px) {
    font-size: 85%;
  }
  @media (max-width: 767px) {
    font-size: 80%;
  }
  @media (max-width: 575px) {
    font-size: 75%;
  }
  @media (max-width: 450px) {
    font-size: 62%;
  }
}
