@import "../../../assets/commonStyle/colors";
@import "../../../assets/commonStyle/mixins";

.report_main_container {
    margin: 0 auto;
    background-color: #fff;
    position: relative;
    width: 1920px !important;
    @media (max-width: 1920px) {
        width: 100% !important;
    }
    .report_details_inner {
        min-height: calc(100vh - 20rem);
        padding: 0.8rem 0.8rem 3rem 0.8rem;
    }
}

.report_details_inner {
    .report_inner_section_devider {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }
    section {
        .accordian_body_outer {
            border-radius: 0.5rem;
            box-shadow: none;
            border: #f2f2f2 solid 0.1rem;
            background-color: #f2f2f2;
        }
        .accordian_summary_outer {
            border-radius: 0.5rem 0.5rem 0 0;
            @include fontStyle(#000, 1rem, 400);
        }
        .MuiAccordionSummary-expandIconWrapper {
            color: #005a88;
            transform: none;
        }
        .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
            color: #ff0000;
        }
        .accordian_details_outer {
            background-color: #fff;
            border-radius: 0 0 0.5rem 0.5rem;
        }
        .MuiAccordionSummary-content {
            padding-right: 1rem;
        }
    }
}

// FIXED HEADER
.report_main_container {
    position: relative;
}

.report_header1 {
    transition: position 0.5s ease-in-out;
    width: 100%;
    left: auto;
    top: auto;
    right: auto;
    transform: none;
}

.report_header1_not_scrolled {
    position: fixed;
    top: 0;
    width: 1920px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 1920px) {
        width: 100%;
    }
    z-index: 9;
    background-color: #fff;
}

.report_header1_scrolled {
    position: relative;
}

.report_header2 {
    margin-top: 3.5rem;
}

// TOKEN PART
.token_dialog_container {
    height: 100vh;
    width: 100vw;
    background-color: #005a88;
}

.token_generate_dialog_outer {
    .token_generate_dialog_header {
        border-bottom: solid #00000033 0.05rem;
        padding: 0.5rem 1rem;
        @include displayFlex(space-between, center);
        p {
            @include fontStyle(#000, 1.2rem, 500);
        }
        .close_icon {
            font-size: 1.5rem;
        }
    }
    .header_part {
        p {
            padding: 1rem;
            text-align: center;
            @include fontStyle(#000, 1.2rem, 500);
        }
    }
    .dialog_content_part {
        button {
            display: block;
            margin: 3rem auto 1rem auto;
        }
        .custom_error {
            text-align: center;
            @include fontStyle(#f00, 1rem, 400);
            padding-top: 0.5rem;
        }
    }
}

.side_selection_button_toggle::-webkit-scrollbar {
    width: 0.8rem; /* Width of the scrollbar */
}

.side_selection_button_toggle::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
}

.side_selection_button_toggle::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 0; /* Round the scrollbar thumb */
    border: 0.1rem solid #f1f1f1; /* Adds padding around the scrollbar thumb */
}

.side_selection_button_toggle::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker color when hovering over the scrollbar thumb */
}

/* Scrollbar styles for Firefox */
.side_selection_button_toggle {
    scrollbar-width: thin; /* Makes the scrollbar thinner */
    scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}

.report_main_container::-webkit-scrollbar {
    width: 0.8rem; /* Width of the scrollbar */
}

.report_main_container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
}

.report_main_container::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 0; /* Round the scrollbar thumb */
    border: 0.1rem solid #f1f1f1; /* Adds padding around the scrollbar thumb */
}

.report_main_container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker color when hovering over the scrollbar thumb */
}

/* Scrollbar styles for Firefox */
.report_main_container {
    scrollbar-width: thin; /* Makes the scrollbar thinner */
    scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}

.error_viewer_outer {
    height: 100%;
    @include displayFlex(center, center);
    padding: 1.5rem;
    p {
        text-align: center;
        @include fontStyle(#000, 2rem, 700);
    }
}
