// light theme
$lightPrimary: #025985;
$lightSecondary: #f1f1f1;

// dark theme
$darkPrimary: #333;
$darkSecondary: #4f4f4f;

// other colors - Color name will be proper like red / darkRed / darkRed1 etc. Don't use like danger or primary / secomdary.
$white: #fff;
$black:#000000;
$shadowGrey:#5a5656;
$borderWhite:#ccc;
$errorColor:#ff0000;
$backgroundInput:#d9d9d9;
$errorBackgroundInput:#ffd2d2;



