@import "../../../assets/commonStyle/colors";
@import "../../../assets/commonStyle/mixins";

// SLIDER TOP
.report_vehicle_info_wise_view {
    .MuiOutlinedInput-root {
        background-color: #d9d9d980;
        &.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #b2b2b2;
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: #b2b2b2;
        }
    }
    .side_selection_dropdown {
        width: 17rem;
        @media (max-width: 575px) {
            display: none;
        }
    }
    .side_selection_button_toggle {
        max-width: 100%;
        overflow-x: auto;
        border-radius: 0;
        button {
            white-space: nowrap;
            background-color: transparent;
            border: none;
            border-radius: 0;
            padding: 0.25rem 1rem;
        }
        .css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
            border-bottom: solid 0.1rem #444;
        }
        display: none;
        @media (max-width: 575px) {
            display: flex;
        }
    }
    .rounded_clickable_button_container {
        @include displayFlex(center, center);
        flex-wrap: wrap;
        button {
            max-width: 1rem;
            max-height: 1rem;
            min-width: 1rem;
            min-height: 1rem;
            border-radius: 100%;
            background-color: #d9d9d9;
            &:not(:last-child) {
                margin-right: 0.5rem;
            }
            @media (max-width: 575px) {
                padding: 0.25rem;
            }
        }
        .selectedSideRoundedElement {
            background-color: #000;
        }
    }
    .slider_view_container_wrapper {
        position: relative;
        margin-top: 1rem;
        touch-action: pan-y;
        img {
            pointer-events: none;
        }
        ::selection {
            pointer-events: none;
        }
        .icon_outer {
            box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: #fff;
            border-radius: 100%;
            width: 2rem;
            height: 2rem;
            z-index: 1;
            @include displayFlex(center, center);
        }
        .prev_icon_outer {
            left: 0.5rem;
            @media (max-width: 767px) {
                left: 0;
            }
        }
        .next_icon_outer {
            right: 0.5rem;
            @media (max-width: 767px) {
                right: 0;
            }
        }
        .prev_next_icon {
            @include fontStyle(#000000, 2rem, 500);
        }
        .slider_view_container {
            padding: 1rem 0;
            @include displayFlex(center, stretch);
            @media (max-width: 767px) {
                align-items: flex-start;
            }
            overflow: hidden;
            @media (max-width: 767px) {
                padding: 1rem 2.5rem;
            }
            .slider_conatiner_outer {
                min-width: 40rem;
                max-width: 40rem;
                min-height: 17rem;
                @media (max-width: 767px) {
                    min-width: 100%;
                    max-width: 100%;
                }
                margin: 0 1rem;
            }
        }
    }
}

// SLIDER TOP INNER SLIDE

.info_body_outer {
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.12);
    border-radius: 0.5rem;
    border: 0.1rem solid #f2f2f2;
    height: 100%;
    .top_part {
        padding: 0.5rem 1.5rem;
        border-bottom: solid 0.1rem #dfdfdf;
        @include displayFlex(flex-start, center);
        img {
            height: 1.75rem;
        }
        p {
            padding-left: 1rem;
            @include fontStyle(#4b4a47, 1rem, 500);
        }
    }

    .body_part {
        padding: 1rem 1.5rem;
    }

    .body_part {
        .data_part_container {
            @include displayFlex(flex-start, stretch);
            flex-wrap: wrap;
            margin-right: -1rem;
            margin-bottom: -1rem;
            .data_part_outer {
                min-width: calc(100% / 3);
                @media (max-width: 575px) {
                    min-width: calc(100% / 2);
                }
                padding-right: 1rem;
                margin-bottom: 1rem;
                .left_part {
                    p {
                        @include fontStyle(#706f6d, 0.9rem, 400);
                    }
                }
                .right_part {
                    padding-top: 0.25rem;
                    p {
                        @include fontStyle(#000, 1rem, 500);
                    }
                }
                p {
                    word-wrap: break-word;
                }
            }
        }
    }
}

.inpection_location_outer {
    .location_body_part {
        padding: 0;
    }
    .body_part {
        height: 11rem;
        .location_img_outer {
            height: 100%;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
            }
            .color_radius_pointer {
                position: absolute;
                bottom: 1rem;
                right: 0.8rem;
                background-color: #fff;
                border: #d9d9d9 solid 0.1rem;
                border-radius: 0.5rem;
                padding: 0.7rem 1.5rem 0.7rem 0.5rem;
            }
            .centered_div_with_img {
                position: absolute;
                height: 4rem;
                width: 4rem;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background-color: #e2d8139b;
                border-radius: 100%;
                @include displayFlex(center, center);
                img {
                    width: 1.25rem;
                    height: auto;
                }
            }
        }
    }
    .bottom_part {
        padding: 0.5rem 1.5rem;
        p {
            display: inline;
            text-transform: capitalize;
            @include fontStyle(#4b4a47, 1rem, 500);
        }
        span {
            display: inline;
            padding-left: 1rem;
            @include fontStyle(#4b4a47, 1rem, 400);
        }
    }
}

.inpection_coverage_outer {
    .coverage_body_part {
        padding: 1rem;
        .coverage_body_outer {
            @include displayFlex(flex-start, stretch);
            height: 100%;
            height: 100%;
            .left_img {
                width: 10rem;
                height: 10.5rem;
                position: relative;
                @include displayFlex(center, center);
                flex-wrap: wrap;
                .only_car_outer {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    img {
                        height: 7rem;
                    }
                }
                .circle_image_box {
                    width: 50%;
                    &:nth-child(2) {
                        margin-bottom: -0.9rem;
                        margin-right: -0.17rem;
                    }
                    &:nth-child(3) {
                        margin-bottom: -0.9rem;
                    }
                    &:nth-child(4) {
                        margin-right: -0.17rem;
                    }
                }
            }
            .right_data {
                width: calc(100% - 10rem);
                padding-left: 1rem;
                .top_data_outer {
                    width: 100%;
                    .data_time_outer {
                        @include displayFlex(flex-start, center);
                        &:not(:last-child) {
                            padding-bottom: 0.5rem;
                        }
                        &:last-child {
                            padding-top: 0.5rem;
                            border-top: solid 0.1rem #dfdfdf;
                        }
                        p {
                            font-size: 0.9rem;
                            white-space: nowrap;
                        }
                        .left_part {
                            width: 5rem;
                            p {
                                font-weight: 400;
                            }
                        }
                        .right_part {
                            p {
                                color: #005a88;
                                font-weight: 500;
                            }
                        }
                    }
                }
                .bottom_pointers {
                    padding-top: 1rem;
                    .pointer_outer {
                        @include displayFlex(flex-start, center);
                        &:not(:last-child) {
                            margin-bottom: 0.7rem;
                        }
                        img {
                            width: 1.2rem;
                        }
                        p {
                            padding-left: 0.8rem;
                            @include fontStyle(#272522, 0.9rem, 400);
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(10px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.current_slide_fade_in {
    animation: fadeIn 0.5s ease-in-out;
}

.previous_slide,
.next_slide {
    opacity: 0.5;
    @media (max-width: 767px) {
        display: none;
    }
}

// PIN DAMAGES
.pin_damages_outer {
    margin: 0 auto;
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.12);
    border-radius: 1rem;
    border: 0.1rem solid #f2f2f2;
    table {
        width: 100%;
        border-collapse: collapse;
        thead {
            th {
                background-color: #f0f0f0;
                color: #333;
                padding: 0.7rem 0.5rem;
                border: 0.1rem solid #e0e0e0;
                font-size: 1rem;
                @media (max-width: 575px) {
                    font-size: 0.8rem;
                    font-weight: 500;
                }
                text-align: left;
                font-weight: 600;
                border-top: none;
                &:first-child {
                    width: 9rem;
                    border-left: none;
                    border-top-left-radius: 1rem;
                }
                &:last-child {
                    border-right: none;
                    border-top-right-radius: 1rem;
                }
            }
        }
        tbody {
            td {
                padding: 0.7rem 0.5rem;
                border: 0.1rem solid #e0e0e0;
                font-size: 1rem;
                text-align: left;
                &:first-child {
                    width: 9rem;
                    background-color: #f0f0f0;
                    border-left: none;
                }
                &:last-child {
                    border-right: none;
                }
                .sub_text {
                    font-size: 0.8rem;
                    color: #888;
                    padding-top: 0.5rem;
                    font-weight: 500;
                }
                ._head_text {
                    font-weight: 600;
                    @media (max-width: 575px) {
                        font-size: 0.8rem;
                        font-weight: 500;
                    }
                }
            }
            tr:last-child {
                td {
                    border-bottom: none;
                    &:first-child {
                        border-bottom-left-radius: 1rem;
                    }

                    &:last-child {
                        border-bottom-right-radius: 1rem;
                    }
                }
            }
        }
    }
}

.graph_img_and_data_outer {
    @include displayFlex(flex-start, center);
    .img_part {
        width: 17rem;
        @media (max-width: 575px) {
            width: 10rem;
        }
        img {
            width: 100%;
        }
    }
    .data_part {
        width: calc(100% - 17rem);
        padding: 0 1rem 0 1.5rem;
        @media (max-width: 575px) {
            padding-left: 1rem;
        }
        @media (max-width: 575px) {
            width: calc(100% - 10rem);
        }
        .data_divider_part {
            &:not(:last-child) {
                padding-bottom: 2rem;
            }
            &:last-child {
                padding-top: 2rem;
            }
            .data_text_outer {
                @include displayFlex(flex-start, center);
                &:not(:last-child) {
                    margin-bottom: 0.5rem;
                }
                p {
                    @include fontStyle(#272522, 1rem, 400);
                    @media (max-width: 575px) {
                        font-size: 0.85rem;
                    }
                }
                .left_text {
                    width: 10rem;
                    padding-right: 1rem;
                    @media (max-width: 575px) {
                        width: max-content;
                        padding-right: 0.5rem;
                    }
                }
                .right_text {
                    width: calc(100% - 10rem);
                    p {
                        margin: 0;
                        font-weight: 500;
                    }
                    @media (max-width: 575px) {
                        width: max-content;
                    }
                }
            }
        }
    }
}

// CODINIONAL CLASSES

.red_text_condition {
    color: #c90606 !important;
}

.orange_text_condition {
    color: #fa7700 !important;
}

.yellow_text_condition {
    color: #fec141 !important;
}

.light_green_text_condition {
    color: #00de25 !important;
}

.deep_green_text_condition {
    color: #036912 !important;
}

.green_text {
    color: #008000 !important;
}

.green_text p {
    color: #008000 !important;
}

.red_text {
    color: #ee254d !important;
}

.red_text p {
    color: #ee254d !important;
}

.yellow_text {
    color: #e4b02c !important;
}

.yellow_text p {
    color: #e4b02c !important;
}

// CARSCORE
.vehicle_info_with_green_tick {
    .body_part {
        .data_part_outer {
            .left_part {
                @include displayFlex(flex-start, center);
                img {
                    width: 1rem;
                    margin-right: 0.5rem;
                }
            }
        }
        .green_tick_representation {
            margin-top: 1rem;
            @include displayFlex(flex-start, center);
            .left_part {
                @include displayFlex(flex-start, center);
                width: 2rem;
                img {
                    width: 1rem;
                }
            }
            .right_part {
                width: calc(100% - 2rem);
                @include displayFlex(flex-start, center);
                border: 0.1rem solid #dcd9d7;
                border-radius: 0.3rem;
                background-color: #f1f0ee;
                padding: 0.25rem 0.5rem;
                img {
                    width: 1rem;
                }
                p {
                    padding-left: 1rem;
                    @include fontStyle(#4e4b4a, 1rem, 400);
                }
            }
        }
    }
}

// GT

.gt_tu_details_body_part {
    hr {
        width: 100%;
        margin-bottom: 1rem;
    }
    .full_width_info_body {
        @include displayFlex(flex-start, flex-start);
        border: 0.1rem solid #dcd9d7;
        border-radius: 0.3rem;
        background-color: #f1f0ee;
        padding: 0.25rem 0.5rem;
        margin-top: 1rem;
        img {
            width: 1rem;
        }
        p {
            padding-left: 1rem;
            @include fontStyle(#4e4b4a, 1rem, 400);
        }
    }
}