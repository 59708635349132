@import "../../assets/commonStyle/colors";
@import "../../assets/commonStyle/mixins";

.containerWithDarkTheme {
  background-color: $darkPrimary;
  color: $white;
  p,
  span,
  label {
    color: $white;
  }
  .mainThemeBody {
    background-color: $darkPrimary;
    color: $white;
    p,
    span,
    label {
      color: $white;
    }
    input {
      color: $black;
    }
  }
  .mainRouteBody {
    background-color: $darkSecondary;
  }
  .selectedSubMenuItem {
    background-color: $lightPrimary;
  }
}

.containerWithLightTheme {
  background-color: $white;
  color: $darkPrimary;
  p,
  span,
  label {
    color: $darkPrimary;
  }
  .mainThemeBody {
    background-color: $lightPrimary;
    color: $white;
    p,
    span,
    button,
    label {
      color: $white;
    }
  }
  @media (max-width: 30rem){
  .sidepanelBodyouter{
    height: 100%;
    position: fixed;
    overflow-x: hidden;
    padding-top: 60px;
    z-index: 2;
  }
}


  .sidepanelBodyouter,
  .sidePanelBody {
    background-color: $lightSecondary;
    color: $darkPrimary;
    p,
    span,
    label {
      color: $darkPrimary;
    }
  }

  .clickedButton {
    background-color: $lightPrimary;
    color: $white;
    p,
    span,
    label {
      color: $white;
    }
  }
  .mainRouteBody {
    background-color: $white;
    padding-top: 5.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
}

.calculatedWidth {
  width: calc(25% - 2.5rem);
}

$header_width: 5rem;
$sidepanel_width: 19rem;
$sidepanel_width_mobile: 6rem;

.burgerMenuIcon{
  display: none;
  @media screen and (max-width: 48rem){
    display:block;
  }
}

.mainContainerOuter {
  height: 100vh;
  width: 100vw;
  .headerContainer {
    @include displayFlex(space-between, center);
    width: 100%;
    position: fixed;
    height: $header_width;
    padding: 1.5rem 1.5rem;
    top: 0;
    z-index: 999;
    .burgerMenu {
      width: 26%;
    p {
      width:100%;
      font-size: 1.25rem;
      line-height: 1.75rem;
      display: block;
      padding: 1rem 0 1rem 1rem;
      font-weight: 700;
    }
  }

  @media (min-width: 30rem) and (max-width: 80rem){
    .burgerMenu {
      width: 36%;
      p {
        width:100%;
        font-size: 1rem;
      }
    }
  }
      @media screen and (max-width: 48rem) {
       .burgerMenu {
      width: 8%;
      button {
        margin-left: -1.75rem;
      }
      p {
        display: none;
      }
    }
    }
    
    .companyDetails {
      font-size: 0.8rem;
      width: 5rem;
      margin-right: 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      padding: 0.3rem;
    }
   
    .tooltip {
      position: relative;
      display: inline-block;
    }
    
    .tooltip .tooltiptext {
      visibility: hidden;
      width: 10rem;
      background-color:$black;
      text-align: center;
      border-radius: 0.35rem;
      padding: 0.31rem 0;
    
      position: absolute;
      z-index: 1;
    }
    
    .tooltip:hover .tooltiptext {
      visibility: visible;
    }
     
    @media screen and (min-width: 30rem) {
      .companyDetails {
        font-size: 1rem;
        width:6rem;
        margin-left: 1.5rem;
      }
    }
    .autoCompleteSearch {
      color: $black;
      background-color: $white;
      border-radius: 0.37rem;
      width: 100%;
    }
    @media screen and (min-width: 30rem) {
      .autoCompleteSearch {
        width: 40%;
      }
    }
    .onBoardNewClicent {
      @include displayFlex(center, center);
      margin-left: 0.5rem;
      margin-right: 0.3rem;
      width: 20%;
        p {
          display: block;
          font-size: large;
        }
    
    @media screen and (max-width: 30rem){
      width: 5%;
      p {
        display: none;
      }
    }

    @media (min-width:30rem) and (max-width:80rem){
      width:40%;
      justify-content:flex-start;
    }
  }
    
    .themeAndLogin {
      @include displayFlex(end, center);
      width: 30%;
      margin-right: -1rem;
      p {
        margin: 0;
        display: none;
      }
      .switchIcon {
        margin: 0;
        padding: 0;
        width: 54%;
      }
    }
    @media screen and (min-width: 30rem) {
      .themeAndLogin {
        width: 10%;
        margin-right: 0;
        p {
          display: block;
        }
      }
    }
  }
  .mainBodyContainer {
    padding-top: $header_width;
    height: 100%;
    width: 100%;
    @include displayFlex(space-between, stretch);
    .sidepanelBodyouter {
      height: 100%;
      width: $sidepanel_width;
      padding: 0.7rem 0;
      overflow-y: auto;
      .sidepanelBodyInner {
        background-color: transparent;
        width: 100%;
        height: 100%;
      }
    }
    .mainRouteBody {
      position:relative;
      height: 100%;
      padding: 1.5rem 1rem;
      width: calc(100% - $sidepanel_width);
      @media (max-width: 30rem){
        width: 26rem
      }
      .mainRouteBodyInner {
        height: 100%;
        overflow-y: auto;
        padding: 0 0.5rem;
      }
      .selectCompanyHeading {
        width: 100%;
        height: 100%;
        @include displayFlex(center, center);
        p {
          font-size: 1.8rem;
          font-weight: 500;
          max-width: 33rem;
          text-align: center;
          @media (max-width: 30rem) {
            max-width: none;
          }
        }
      }
    }
  }
}


.mainMenuWrappers {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  .mainMenuButtons {
    border-radius: 0;
    padding: 0.75rem 1.25rem 0.75rem 1rem;
    justify-content: flex-start;
    position: relative;
    text-transform: capitalize;
    font-size: 1rem;
    .menuArrow {
      position: absolute;
      right: 1rem;
    }
  }
}

.subMenuItem_dark {
  &:hover {
    @include color_bgcolor($black, $lightSecondary);
  }
}

.subMenuItem_light {
  &:hover {
    @include color_bgcolor($white, $lightPrimary);
  }
}

.selectedSubMenuItemDark {
  @include color_bgcolor($black, $lightSecondary);
  &:hover {
    @include color_bgcolor($black, $lightSecondary);
  }
}

.selectedSubMenuItemLight {
  @include color_bgcolor($white, $lightPrimary);
  &:hover {
    @include color_bgcolor($white, $lightPrimary);
  }
}

.headingDarkTheme{
  color: $black;
}

.headingLightTheme{
  color:$white;
}
